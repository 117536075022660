/* global Promise document, Office, module, require */

export const writeFileNamesToOfficeDocument = (result: string | any[]) => {

    return new OfficeExtension.Promise(function (resolve, reject) {
        try {
            switch (Office.context.host) {
                case Office.HostType.Excel:
                    writeFileNamesToWorksheet(result);
                    break;
                case Office.HostType.Word:
                    writeFileNamesToDocument(result);
                    break;
                default:
                    throw "Unsupported Office host application: This add-in only runs on Excel, PowerPoint, or Word.";
            }
            resolve();
        }
        catch (error) {
            reject(Error("Unable to add filenames to document. " + (error as any).toString()));
        }
    });    
}

function writeFileNamesToWorksheet(result: string | any[]) {

     return Excel.run(function (context) {
        var sheet = context.workbook.worksheets.getActiveWorksheet();

        var filenames = [];
        var i;
        for (i = 0; i < result.length; i++) {
            var innerArray = [];
            innerArray.push(result[i]);
            filenames.push(innerArray);
        }

        var rangeAddress = "B5:B" + (5 + (result.length - 1)).toString();
        var range = sheet.getRange(rangeAddress);
        range.values = filenames;
        range.format.autofitColumns();

        return context.sync();
    });
}

function writeFileNamesToDocument(result: string | any[]) {

     return Word.run(function (context) {

        var documentBody = context.document.body;
        for (var i = 0; i < result.length; i++) {
            documentBody.insertParagraph(result[i], "End");
        }

        return context.sync();
    });
}

export default writeFileNamesToOfficeDocument;