/* global Promise document, Office, module, require */

// import { writeFileNamesToOfficeDocument } from './OfficeUtils/Document';
import { format } from "date-fns";
import i18next from "i18next";
import { createPinia } from "pinia";
import { createApp } from "vue";
import i18n from "./utils/i18n/i18n";
import App from "./App.vue";
import { getPsySocCase } from "./utils/GraphUtils/GraphData";
import { useCurrentDocStore } from "./stores/currentdoc";

document.addEventListener("DOMContentLoaded", function () {
  console.log(format(new Date(), "dd/MM/yyyy"));
  Office.onReady(function (reason: {
    host: Office.HostType;
    platform: Office.PlatformType;
  }) {
    let lng = Office.context.displayLanguage;
    i18next.changeLanguage(lng);
    let pinia = createPinia();
    const app = createApp(App);
    app.use(pinia);

    // Get the current doc and case info
    Office.context.document.getFilePropertiesAsync(async function (
      asyncResult
    ) {
      if (Office.AsyncResultStatus.Succeeded !== asyncResult.status) {
        console.log(asyncResult.error.message);
      } else {
        if (Office.context.host === Office.HostType.Word) {
          Word.run((context: Word.RequestContext): Promise<unknown> => {
            var contentControls = context.document.contentControls;
            context.load(contentControls, "id");

            return context.sync().then(() => {
              const store = useCurrentDocStore();
              store.contentControlList = [];
              if (contentControls.items.length === 0) {
                Promise.resolve("No content control found.");
              } else {
                contentControls.items.forEach((item) => {
                  item.load(
                    "appearance," +
                      "cannotDelete," +
                      "cannotEdit," +
                      "color," +
                      "id," +
                      "placeHolderText," +
                      "removeWhenEdited," +
                      "title," +
                      "text," +
                      "type," +
                      "style," +
                      "tag," +
                      "font/size," +
                      "font/name," +
                      "font/color"
                  );
                });

                return context.sync().then(function () {
                  contentControls.items.forEach((item) => {
                    console.log(`Storing content controls`);
                    // if(store.contentControlList.find(obj => {
                    //   return obj.tag === item.tag
                    // }) === undefined) {
                    store.contentControlList.push({
                      appearance: item.appearance,
                      cannotDelete: item.cannotDelete,
                      cannotEdit: item.cannotEdit,
                      color: item.color,
                      id: item.id,
                      placeHolderText: item.placeholderText,
                      removeWhenEdited: item.removeWhenEdited,
                      title: item.title,
                      text: item.text ?? "",
                      type: item.type,
                      style: item.style,
                      tag: item.tag,
                      font_color: item.font.color,
                      font_name: item.font.name,
                      font_size: item.font.size,
                      docText: item.text ?? "",
                      sharePointValue: "",
                    });
                  //}
                  });
                });
              }
              return Promise.resolve();
            });
          });
        }
        console.log(asyncResult.status.toString());
        var fileUrl = asyncResult.value.url;
        if (fileUrl == "") {
          console.log(
            "The file hasn't been saved yet. Save the file and try again"
          );
        } else {
          console.log(`Docurl: ${Office.context.document.url}`);
          let docUrl = window.btoa(Office.context.document.url);
          console.log(`getExcelDocData - onExcelRun - docUrl: ${docUrl}`);
          await getPsySocCase(docUrl);
        }
      }
    });

    // mount the app
    i18n(app).mount("#app");
  });
});
