<template>
  <HeaderMenu />

  <div class="hidden pl-8 py-12"></div>

  <footer class="h-16 sticky">
    <span class="ml-3 font-bold text-persian-green-900 font-bold">ssd</span>
  </footer>
</template>

<script setup>
import { onMounted } from "@vue/runtime-core";
import HeaderMenu from "./components/HeaderMenu.vue";
console.log("In App.vue");
onMounted(() => {
  console.log("onMounted. Getting doc info");
});
</script>

<style></style>
