<template>
  <div class="flex flex-col">
    <div class="p-2">
      <CaseInfoItem
        :label="$t('common.casedate')"
        :value="store?.SharePointCaseItem?.fields?.CaseDate === null ? 'n.a.' : format(
            parseISO(store?.SharePointCaseItem?.fields?.CaseDate),
            'dd-MM-yyyy'
          )"
      />
      <CaseInfoItem
        :label="$t('common.registrationnumber')"
        :value="store?.SharePointCaseItem?.fields?.RegistrationNumber ?? 'n.a.'"
      />
      <CaseInfoItem
        :label="$t('common.casenumber')"
        :value="store?.SharePointCaseItem?.fields?.id ?? 'n.a.'"
      />
      <CaseInfoItem
        :label="$t('common.socialworkerabbr')"
        :value="
          (store?.SharePointCaseItem?.fields &&
            store?.SharePointCaseItem?.fields['SocialWorker_x003a_Firstname']) +
            '  ' +
            store?.SharePointCaseItem?.fields?.SocialWorker_x003a_Lastname ??
          'n.a.'
        "
      />
      <CaseInfoItem
        :label="$t('common.name')"
        :value="
          store?.SharePointCaseItem?.fields?.ApplicantFirstname ??
          'n.a.' + store?.SharePointCaseItem?.fields?.ApplicantLastname ??
          'n.a.'
        "
      />
      <CaseInfoItem
        :label="$t('common.address')"
        :value="
          store?.SharePointCaseItem?.fields?.ApplicantFullAddress ?? 'n.a.'
        "
      />
      <CaseInfoItem
        :value="store?.SharePointCaseItem?.fields?.ApplicantCity ?? 'n.a.'"
      />

      <CaseInfoItem
        :label="$t('common.phone')"
        :value="
          store?.SharePointCaseItem?.fields?.Mobile ??
          store?.SharePointCaseItem?.fields?.Tel ??
          'n.a.'
        "
      />
      <CaseInfoItem
        :label="$t('common.email')"
        :value="store?.SharePointCaseItem?.fields?.Email ?? 'n.a.'"
      />
      <CaseInfoItem
        :label="$t('common.birthdate')"
        :value="
                    store?.SharePointCaseItem?.fields?.ApplicantBirthDate === null ?
                    'n.a.' :
          format(
            parseISO(store?.SharePointCaseItem?.fields?.ApplicantBirthDate),
            'dd-MM-yyyy'
          )
        "
      />
    </div>

    <ContentControlList />
  </div>
</template>

<script setup>
import { getGraphData } from "../utils/GraphUtils/GraphData";
import { getPsySocCase } from "../utils/GraphUtils/GraphData";
import { usePsySocStore } from "../stores/psysoc";
import CaseInfoItem from "./CaseInfoItem.vue";
import { format } from "date-fns";
import { parseISO } from "date-fns/esm";

const store = usePsySocStore();

function getFilePropertiesAsync() {
  Office.context.document.getFilePropertiesAsync(function (asyncResult) {
    if (Office.AsyncResultStatus.Succeeded !== asyncResult.status) {
      this.logger.error(asyncResult.error.message);
    } else {
      console.log(asyncResult.status.toString());
      var fileUrl = asyncResult.value.url;
      if (fileUrl == "") {
        console.log(
          "The file hasn't been saved yet. Save the file and try again"
        );
      } else {
        console.log(fileUrl);
        console.log(Office.context.document.url);
        let docUrl = window.btoa(Office.context.document.url);
        console.log(`getExcelDocData - onExcelRun - docUrl: ${docUrl}`);
        getPsySocCase(docUrl);
      }
    }
  });
}
</script>
