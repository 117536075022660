<template>
  <div class="px-4 py-1">
    <div class="flex">
      <label
        :for="contentControl.id"
        class="block text-xs text-gray-700"
        >{{ contentControl.title }}
      </label>
    </div>
    <div class="mt-0 flex">
      <input
        :key="'txt_' + contentControl.id"
        v-model="contentControl.text"
        type="text"
        :name="contentControl.id"
        :id="contentControl.id"
        class="block w-64 rounded-md border-gray-300 shadow-sm focus:border-persian-green-500 focus:ring-persian-green-500 text-xs h-12"
        placeholder="Enter text"
      />

      <a
        href="#"
        class="text-persian-green-300 w-3 h-3 hover:text-persian-green-900 pl-2 pr-2"
        @click="highLightCurrentContentControl(contentControl.id)"
        :title="$t('highlitecurrentcontentcontrol_tooltip')"
      >
        <!-- heroicons / mini / bolt -->
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
          fill="currentColor"
          class="w-4 h-4"
        >
          <path
            d="M11.983 1.907a.75.75 0 00-1.292-.657l-8.5 9.5A.75.75 0 002.75 12h6.572l-1.305 6.093a.75.75 0 001.292.657l8.5-9.5A.75.75 0 0017.25 8h-6.572l1.305-6.093z"
          />
        </svg>
      </a>

      
      <Dropdown
      class="ml-4 "
      :contentControl="contentControl"
        v-show="
          contentControl.sharePointValue !== '' &&
          contentControl.sharePointValue !== contentControl.text
        "
      />
    </div>
  </div>
</template>

<script setup>
import Dropdown from "./contentconflictcontroldropdown.vue";
const props = defineProps({
  contentControl: {
    type: Object,
    required: true,
  },
});

function showConflict(id) {}

function highLightCurrentContentControl(id) {
  Word.run((context) => {
    try {
      var contentControl = context.document.contentControls.getById(id);
      context.load(contentControl, "id,font/highlightColor");

      context
        .sync()
        .then(() => {
          if (contentControl.font.highlightColor) {
            contentControl.font.highlightColor = null;
          } else {
            contentControl.font.highlightColor = "yellow";
          }
          contentControl.select();
          context.sync();
        })
        .catch(function (error) {});
    } catch (e) {
      console.log(e);
    }
    return Promise.resolve();
  });
}
</script>
