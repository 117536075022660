<template>
  <div class="flex pb-2">
    <div class="text-xs w-32 font-normal text-gray-500">{{ label }}</div>
    <div class="text-sm text-gray-900">
      {{ value ?? "n.a." }}
    </div>
  </div>
</template>
<script setup>
const props = defineProps({
  label: { type: String, required: false },
  value: { type: String, required: false },
});
</script>
