<template>
  <div class="p-2">
    <CaseInfoItem
      :label="$t('documentdatapanel.doctitle')"
      :value="store?.CaseDocumentListItem?.Title ?? 'n.a.'"
    />
    <div class="flex pb-2">
      <div
        class="text-xs w-24 font-medium text-gray-500"
        v-html="$t('documentdatapanel.docdesc')"
      ></div>
    </div>
    <div class="text-sm text-gray-900">
      {{ store?.CaseDocumentListItem?.Remarks ?? "n.a." }}
    </div>
  </div>
</template>

<script setup>
import { usePsySocStore } from "../stores/psysoc";
import CaseInfoItem from "./CaseInfoItem.vue";

const store = usePsySocStore();
</script>
