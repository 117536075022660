<template>
  <div class="sticky">
  <TabGroup :selectedIndex="selectedTab" @change="changeTab">
    <TabList class="w-full border-b border-gray-200 -mb-px flex">
      <Tab
        :class="[
          selected
            ? 'border-persian-green-500 text-persian-green-600'
            : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300',
          'w-1/3 px-4 px-1 text-center border-b-2 font-medium text-sm',
        ]"
        >{{ $t("headermenu.document_tab_title").toUpperCase() }}</Tab
      >
      <Tab
        :class="[
          selected
            ? 'border-persian-green-500 text-persian-green-600'
            : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300',
          'w-1/3 py-4 px-1 text-center border-b-2 font-medium text-sm',
        ]"
        >{{ $t("headermenu.case_tab_title").toUpperCase() }}</Tab
      >
      <Tab
        :class="[
          selected
            ? 'border-persian-green-500 text-persian-green-600'
            : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300',
          'w-1/3 py-4 px-1 text-center border-b-2 font-medium text-sm',
        ]"
        >{{ $t("headermenu.info_tab_title").toUpperCase() }}</Tab
      >
    </TabList>
    <TabPanels>
      <TabPanel><DocumentInfoPanel /></TabPanel>
      <TabPanel><CaseInfoPanel /></TabPanel>
      <TabPanel><DocumentPropertiesPanel /></TabPanel>
    </TabPanels>
  </TabGroup>
</div>
</template>

<script setup>
import { ref } from "vue";
import { TabGroup, TabList, Tab, TabPanels, TabPanel } from "@headlessui/vue";

import CaseInfoPanel from "./CaseInfoPanel.vue";
import DocumentInfoPanel from "./DocumentInfoPanel.vue";
import DocumentPropertiesPanel from "./DocumentPropertiesPanel.vue";
const selectedTab = ref(0);

function changeTab(index) {
  selectedTab.value = index;
}
</script>