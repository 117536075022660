<template>
  <ContentControlList />

  <div id="waitContainer" style="display: none">
    <div class="ms-Spinner"></div>
    <p
      class="ms-font-l ms-fontColor-themePrimary indentFromPaneEdge centeredText"
    >
      Please complete the authentication in the pop-up window.
    </p>
  </div>

  <footer id="footer" class="homefooter ms-bgColor-neutralLight">
    <div class="container">
      <p align="center">---</p>
    </div>
  </footer>
</template>

<script setup>
import { usePsySocStore } from "../stores/psysoc";
import BoltIcon from "./icons/boltIcon.vue";
import BoltSlashIcon from "./icons/boltSlashIcon.vue";
import ContentControlList from "./ContentControlList.vue";

const store = usePsySocStore();

function addConentControl() {
  Word.run(async (context) => {
    const range = context.document.getSelection();
    range.load("text,font/size,font/color,font/bold,font/italic,font/name");
    await context.sync();
    let rangeText = range.text;
    let fontSize = range.font.size;
    let fontColor = range.font.color;
    let fontBold = range.font.bold;
    let fontItalic = range.font.italic;
    let fontName = range.font.name;
    let title = rangeText;
    let tag = rangeText;
    range.clear();
    const wordContentControl = range.insertContentControl();
    wordContentControl.tag = tag;
    wordContentControl.title = title;
    wordContentControl.insertText(rangeText.toString(), "Replace");
    wordContentControl.cannotEdit = false;
    wordContentControl.appearance = "BoundingBox";
    wordContentControl.font.size = fontSize;
    wordContentControl.font.color = fontColor;
    wordContentControl.font.bold = fontBold;
    wordContentControl.font.italic = fontItalic;
    wordContentControl.font.name = fontName;
    await context.sync();
    let id = wordContentControl.id;
    console.log(`content control inserted: ${id}`);
  }).catch((error) => {
    console.log(error);
  });
}

function showContentControls() {
  Word.run((context) => {
    var contentControls = context.document.contentControls;
    context.load(contentControls, "id");

    context.sync().then(() => {
      if (contentControls.items.length === 0) {
        console.log("No content control found.");
      } else {
        contentControls.items.forEach((item) => {
          item.load(
            "appearance," +
              "cannotDelete," +
              "cannotEdit," +
              "color," +
              "id," +
              "placeHolderText," +
              "removeWhenEdited," +
              "title," +
              "text," +
              "type," +
              "style," +
              "tag," +
              "font/size," +
              "font/name," +
              "font/color"
          );
        });

        return context.sync().then(function () {
          contentControls.items.forEach((item) => {
            
          });
        });
      }
    });
  });
}
</script>
