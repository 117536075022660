import { defineStore } from "pinia";
import { IPSContentControl } from "../interfaces/IPSContentControl";

export const storeNameTest = "currentdoc";

export type RootState = {
    contentControlList: IPSContentControl[];
}
export const useCurrentDocStore = defineStore('currentdoc', {
    state: () => ({ contentControlList: [] } as RootState)
});

export default useCurrentDocStore;