import { defineStore } from "pinia";

export const usePSCaseFieldMappingStore = defineStore("pscasefieldmapping", {
  state: () => {
    return {
      // all these properties will have their type inferred automatically
      fields: {
        CaseId: "Id",
        CaseDate: "CaseDate",
        Grade_Fr: "Grade_x003a_TitleFr",
        Grade_Nl: "Grade_x003a_TitleNl",
        ApplicantFirstName: "ApplicantFirstname",
        ApplicantLastName: "ApplicantLastname",
        ApplicantLanguage: "ApplicantLanguage",
        ApplicantBirthDate: "ApplicantBirthDate",
        AccountNumber: "Accountnumber",
        HomeAddressStreet: "ApplicantFullAddress",
        ApplicantZip: "ApplicantZip",
        ApplicantCity: "ApplicantCity",
        ApplicantPhone: "Tel",
        ApplicantEmail: "Email",
        SocialWorker_x003a_Firstname: "SocialWorker_x003a_Firstname",
        SocialWorker_x003a_Lastname: "SocialWorker_x003a_Lastname",
        RegistrationNumber: "RegistrationNumber",
      },
    };
  },
});
