import i18next from 'i18next';
import I18NextVue from 'i18next-vue';
import Backend from 'i18next-http-backend';

i18next
  // i18next-http-backend
  // loads translations from your server
  // https://github.com/i18next/i18next-http-backend
  .use(Backend)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    load: 'languageOnly',
    debug: true,
    fallbackLng: 'en',
    backend: {
      loadPath: '/content/locales/{{lng}}/{{ns}}.json'
    } 
  });

export default function (app: any) {
  app.use(I18NextVue, { i18next })
  return app
}