<template>
  <form>
    <div class="px-4 py-4 flex w-full justify-around items-start">
      <div class="text-left w-full">
        <button
          @click="fillOutDocument"
          type="button"
          class="inline-flex items-center rounded border border-transparent bg-persian-green-600 px-2.5 py-1.5 text-xs font-medium text-white shadow-sm hover:bg-persian-green-700 focus:outline-none focus:ring-2 focus:ring-persian-green-500 focus:ring-offset-2"
        >
          {{ $t("fillincontentcontrols_button_label") }}
        </button>
      </div>
      <div class="text-right align-top">
        <a
          href="#"
          class="inline-block align-top text-persian-green-300 w-3 h-3 hover:text-persian-green-900 px-2"
          @click="toggleHighLightContentControls"
          :title="$t('highliteallcontentcontrols_tooltip')"
        >
          <!-- heroicons / mini / bolt -->
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
            class="w-4 h-4"
          >
            <path
              d="M11.983 1.907a.75.75 0 00-1.292-.657l-8.5 9.5A.75.75 0 002.75 12h6.572l-1.305 6.093a.75.75 0 001.292.657l8.5-9.5A.75.75 0 0017.25 8h-6.572l1.305-6.093z"
            />
          </svg>
        </a>
      </div>
    </div>
    <div class="ml-2 overflow-y-auto h-96  mr-2 border-2 border-persian-green-900 rounded-md border-solid">
    <div v-for="(field, index) in store.contentControlList">
      <ContentControlItem :contentControl="field" />
    </div>
  </div>
  </form>
</template>

<script setup>
import { useCurrentDocStore } from "./../stores/currentdoc";
import ContentControlItem from "./ContentControlItem.vue";

const store = useCurrentDocStore();

let highLightOn = false;

function fillOutDocument() {
  console.log(`Filling out document`);
  Word.run((context) => {
    store.contentControlList.forEach((field) => {
      try {
        var contentControl = context.document.contentControls.getById(field.id);
        context.load(contentControl, "id,text,tag,title");

        context
          .sync()
          .then(() => {
            contentControl.insertText(field.text ?? "", "Replace");
            context.sync();
          })
          .catch(function (error) {
        console.log(e);});
      } catch (e) {
        console.log(e);
      }
    });
    return Promise.resolve();
  });
}

function toggleHighLightContentControls() {
  /* "Yellow", "Lime", "Turquoise", "Pink", "Blue", "Red", "DarkBlue", "Teal", "Green", "Purple", "DarkRed", "Olive", "Gray", "LightGray", and "Black" */
  highLightOn = !highLightOn;
  Word.run((context) => {
    var contentControls = context.document.contentControls;
    context.load(contentControls, "id");

    context.sync().then(() => {
      if (contentControls.items.length === 0) {
        console.log("No content control found.");
      } else {
        contentControls.items.forEach((item) => {
          item.font.highlightColor = highLightOn ? "Lime" : null;
        });
        return context.sync().then();
      }
    });
  });
}
</script>
